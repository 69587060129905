.forgot-title {
  color: #fff !important;
}

.forgot-subtitle {
  color: #fff;
  font-size: 17px;
  margin-bottom: 1.875rem;
  text-align: center;
}

.forgot-header-logo {
  width: 30%;
}

.forgot-submit-btn {
  color: #fff !important;
  gap: 15px;
  background-color: #1b243a;
  border: none;
}

.forgot-submit-btn:hover {
  background-color: #1b243a !important;
  border: none !important;
}

.forgot-submit-btn:active {
  background-color: #1b243a !important;
  border: none !important;
}

.forgot-submit-btn:focus {
  background-color: #1b243a !important;
  border: none !important;
}

.remember-text-class-link {
  color: #fff !important;
  margin-right: 5px;
}
